import { CreateWebFormAnswersInput } from "@/content-usage/drawer/quizzes/submission-editor/QuizSubmissionEditor"
import { ObservableState } from "@/core/form/store/FormStore"
import { GlobalID, NodeFromConnection } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import { WebFormSubmissionsOrderByInput } from "@/web-form/utils/__generated__/usePaginatedSubmissionsPaginationFragment.graphql"
import { webFormFillerUtils_getFormStateFromRevisionFragment$data } from "@/web-form/utils/__generated__/webFormFillerUtils_getFormStateFromRevisionFragment.graphql"
import { webFormQueryUtils_submissionFragment$data } from "@/web-form/utils/__generated__/webFormQueryUtils_submissionFragment.graphql"
import { webFormQueryUtils_useLearnerSubmissionsQuery } from "@/web-form/utils/__generated__/webFormQueryUtils_useLearnerSubmissionsQuery.graphql"
import { webFormQueryUtils_useWebFormContentUsageQuery } from "@/web-form/utils/__generated__/webFormQueryUtils_useWebFormContentUsageQuery.graphql"
import { getWebFormSubmissionInput } from "@/web-form/utils/webFormFillerUtils"
import { observable } from "mobx"
import { useLazyLoadQuery, useSubscribeToInvalidationState } from "react-relay"
import { graphql } from "relay-runtime"

export type QuizAnswerData = NodeFromConnection<
  webFormQueryUtils_submissionFragment$data["answers"]
>

export type QuizQuestionData = NodeFromConnection<
  webFormFillerUtils_getFormStateFromRevisionFragment$data["questions"]
>

export type QuizSubmission =
  | Omit<webFormQueryUtils_submissionFragment$data, " $fragmentType">
  | undefined

export type QuizRevision = Omit<
  webFormFillerUtils_getFormStateFromRevisionFragment$data,
  " $fragmentType"
> | null

export type QuizResult = webFormQueryUtils_submissionFragment$data["result"] | undefined

export type UseWebFormSubmissionQueryReturnType = {
  submission: QuizSubmission
  result: QuizResult
}

/** Query a content usage */
export function useWebFormContentUsageQuery({ usageId }: { usageId: GlobalID }) {
  const { contentUsage } =
    useLazyLoadQuery<webFormQueryUtils_useWebFormContentUsageQuery>(
      graphql`
        query webFormQueryUtils_useWebFormContentUsageQuery($usageId: ID!) {
          contentUsage: node(id: $usageId) {
            ... on ContentUsage {
              id
              passPercentage
              module {
                isCurriculumModule
              }
            }
          }
        }
      `,
      { usageId }
    )

  return contentUsage
}

/**
 *  Querys the viewer's own submissions for the web form revision & usage
 */
export function useWebFormSubmissionsQuery({
  usageId,
  revisionId = "",
  submissionIds = [],
  include = true,
  first = 100,
  orderBy,
  isRetrying = false,
}: {
  usageId?: GlobalID
  revisionId?: GlobalID
  submissionIds?: GlobalID[]
  include?: boolean
  first?: number
  orderBy?: WebFormSubmissionsOrderByInput
  isRetrying?: boolean
}): {
  submissions: UseWebFormSubmissionQueryReturnType[]
  revision: QuizRevision
  totalCount: number
  refetch: () => void
} {
  const queryVars = {
    revisionId,
    usageId,
    submissionIds,
    include: Boolean(revisionId && usageId) && include,
    includeSubmissions: !isRetrying,
    first,
    orderBy,
  }
  const [{ node }, refetchQuery] =
    Relay.useRefetchableQuery<webFormQueryUtils_useLearnerSubmissionsQuery>(
      graphql`
        query webFormQueryUtils_useLearnerSubmissionsQuery(
          $revisionId: ID!
          $usageId: ID
          $submissionIds: [ID!]
          $orderBy: WebFormSubmissionsOrderByInput
          $include: Boolean!
          $includeSubmissions: Boolean!
          $first: Int
        ) {
          node(id: $revisionId) @include(if: $include) {
            ... on WebFormRevision {
              id
              __typename
              ...webFormFillerUtils_getFormStateFromRevisionFragment @relay(mask: false)
              viewerSubmissions(
                contentUsageId: $usageId
                submissionIds: $submissionIds
                orderBy: $orderBy
                first: $first
              ) @include(if: $includeSubmissions) {
                __id
                totalCount
                edges {
                  node {
                    ...webFormQueryUtils_submissionFragment @relay(mask: false)
                  }
                }
              }
            }
          }
        }
      `,
      queryVars,
      { fetchPolicy: "store-and-network" }
    )
  const revision = Relay.narrowNodeType(node ?? null, "WebFormRevision")
  const submissions = Relay.connectionToArray(revision?.viewerSubmissions)

  function refetch() {
    refetchQuery(queryVars, { inBackground: true })
  }

  // if a new submission is added this connection gets invalidated
  useSubscribeToInvalidationState(
    revision?.viewerSubmissions?.__id ? [revision.viewerSubmissions.__id] : [],
    refetch
  )

  if (isRetrying) {
    return {
      submissions: [],
      revision,
      totalCount: 0,
      refetch,
    }
  }

  return {
    submissions: submissions.map((submission) => ({
      submission,
      result: submission.result,
    })),
    revision,
    totalCount: revision?.viewerSubmissions?.totalCount || 0,
    refetch,
  }
}

export function getQuizSubmissionInput(
  r: QuizRevision,
  s: UseWebFormSubmissionQueryReturnType["submission"] | null = undefined
): ObservableState<Omit<CreateWebFormAnswersInput, "contentUsageId">> {
  const input = getWebFormSubmissionInput(
    r,
    Relay.connectionToArray(s?.answers).map((a) => ({
      ...a,
      selectedOptions: [...(a?.selectedOptions || [])],
    })),
    { neverSkipped: false }
  )!

  return {
    ...input,
    answers: observable.array(
      input.answers.map((a) => ({
        ...a,
        selectedOptions: observable.array(a.selectedOptions || undefined),
      }))
    ),
  }
}

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment webFormQueryUtils_submissionFragment on WebFormSubmission {
    id
    webFormId
    webFormRevisionId
    completedAt
    durationSeconds
    hasAnswerKey
    hasPassed
    answers {
      edges {
        node {
          ...webFormQueryUtils_answerFragment @relay(mask: false)
        }
      }
    }
    result {
      score
      total
      percentage
      correctAnswers {
        id
      }
      incorrectAnswers {
        id
      }
    }
  }
`

// eslint-disable-next-line no-unused-expressions
graphql`
  fragment webFormQueryUtils_answerFragment on WebFormAnswer {
    id
    body
    selectedOptions
    webFormQuestionId
    isSkipped
    isCorrect
    result {
      correctIds
      incorrectSelections
    }
  }
`
