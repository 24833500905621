import AssignmentSubmissionDetails from "@/product/course/curriculum/section/drawer/items/assignment/tabs/AssignmentSubmissionDetails"
import { GlobalID } from "@/relay/RelayTypes"
import { useParams } from "react-router-dom"

function WebViewAssignmentSubmissionPage() {
  const { assignmentSubmissionId } = useParams<{ assignmentSubmissionId: GlobalID }>()
  return <AssignmentSubmissionDetails submissionId={assignmentSubmissionId} />
}

export default WebViewAssignmentSubmissionPage
